import React, { memo } from 'react';

import { CommentModel } from '@api/comments/CommentModel';
import Box from '@components/Box';
import OwnerItem from '@components/Owners/OwnerItem';
import theme from '@styles/theme';

interface MessageInfoProps {
  comment: CommentModel;
}

const MessageInfo = ({ comment }: MessageInfoProps) => {
  const { commentedBy: author } = comment;
  const postedTime = comment.commentedOn?.fromNow();

  return (
    <Box
      alignItems="center"
      compDisplay="inline-flex"
      gap={2}
      justifyContent="flex-start"
      minHeight="1.75rem"
    >
      <OwnerItem bold contentAlignment="center" height="40px" owner={author} showAvatar showTags />
      <Box as="span" color={theme.colors.v1.gray[500]} fontSize={theme.typography.fontSizes.sm}>
        {postedTime}
      </Box>
    </Box>
  );
};

export default memo(MessageInfo);
