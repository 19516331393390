import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Image from '@components/UI/Image';

interface ImageMessageProps {
  message: React.ReactNode;
  src: string;
}
const ImageMessage: React.FC<ImageMessageProps> = ({ message, src }) => {
  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      flexDirection="column"
      gap={1}
      justifyContent="start"
      mb={2}
      mt={3}
    >
      <Image alt="No content" height="auto" src={src} width="120px" />
      {message && (
        <Text color="gray.500" fontSize="body2">
          {message}
        </Text>
      )}
    </Box>
  );
};

export default ImageMessage;
