import React from 'react';

import ImageMessage from '@components/ImageMessage';

interface EmptyContentProps {
  message: React.ReactNode;
}
const EmptyContent: React.FC<EmptyContentProps> = ({ message }) => {
  return <ImageMessage message={message} src="/images/empty-content.png" />;
};

export default EmptyContent;
