import React, { useMemo } from 'react';

import { useFetchDataSourcesHierarchy } from '@api/dataSources';
import useEditableDataSources from '@components/SettingsSidebar/useEditableDataSources';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import flags from '@features';
import { ConnectableApps, useConnectableApps } from '@hooks/useConnectableApps';
import { HierarchyData } from '@models/HierarchyModel';

const billingMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: '/admin/billing',
  iconEl: <Icon color="currentColor" name="credit-card" size="16px" />,
  name: 'Billing',
  objectType: 'menu-item',
  url: '/admin/billing',
};

const rbacMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: '/admin/access-control',
  iconEl: <Icon color="currentColor" name="access-control" size="16px" />,
  name: 'Access Control',
  objectType: 'menu-item',
  url: '/admin/access-control',
};

const customAttributeMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: '/admin/data/custom-attribute',
  iconEl: <Icon color="currentColor" name="submenu" size="16px" />,
  name: 'Custom Attribute',
  objectType: 'menu-item',
  url: '/admin/data/custom-attribute',
};

const constAnalysisMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: '/admin/cost-analysis',
  iconEl: <Icon color="currentColor" name="dollar-circle" size="16px" />,
  name: 'Snowflake Cost Analysis',
  objectType: 'menu-item',
  url: '/admin/cost-analysis',
};

const automatedPiiMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: '/admin/automated-pii',
  iconEl: <Icon color="currentColor" name="lock-filled" size="14px" />,
  name: 'Automated PII Detection',
  objectType: 'menu-item',
  url: '/admin/automated-pii',
};

const slackMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: '/admin/slack',
  iconEl: <Icon color="currentColor" name="slack" size="16px" />,
  name: 'Slack',
  objectType: 'menu-item',
  url: '/admin/slack',
};

const monteCarloMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: '/admin/monte-carlo',
  iconEl: <Icon color="currentColor" name="monte-carlo" size="16px" />,
  name: 'Monte Carlo',
  objectType: 'menu-item',
  url: '/admin/monte-carlo',
};

const githubMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: '/admin/github',
  iconEl: <Icon color="currentColor" name="github" size="16px" />,
  name: 'GitHub',
  objectType: 'menu-item',
  url: '/admin/github',
};

const useAdminHierarchy = () => {
  const { isOrgAdmin, organization } = useUserContext();

  const { data } = useFetchDataSourcesHierarchy({
    params: {
      exclude_docs: false,
      max_level: 'datasource',
      org_guid: organization?.guid || '',
    },
  });

  const { dataSources: allowedDataSources } = useEditableDataSources({ data: data?.data ?? [] });

  const { connectedApps } = useConnectableApps();

  const appsMenuChildren = useMemo(() => {
    const apps = [slackMenuItem];
    if (connectedApps.includes(ConnectableApps.github)) apps.push(githubMenuItem);
    if (connectedApps.includes(ConnectableApps.monteCarlo)) apps.push(monteCarloMenuItem);

    return apps;
  }, [connectedApps]);

  const dataSourcesItem = useMemo(() => {
    const dataSourcesItemChildren = [
      ...allowedDataSources.filter((ds) => ds.type !== 'monte_carlo'),
      ...(isOrgAdmin ? [customAttributeMenuItem] : []),
    ];

    return dataSourcesItemChildren.length > 0
      ? [
          {
            breadcrumbLabelList: [],
            children: dataSourcesItemChildren,
            guid: '/admin/data',
            iconEl: <Icon color="currentColor" name="database" size="18px" />,
            name: 'Data',
            objectType: 'menu-item',
            url: '/admin/data',
          },
        ]
      : [];
  }, [isOrgAdmin, allowedDataSources]);

  const adminHierarchy = useMemo(() => {
    if (flags.billing && (organization?.isTrialExpired || organization?.isSubscriptionEnded))
      return [billingMenuItem];

    const settings = organization?.settings;
    const dataSources = data?.data ?? [];
    const hasSnowflake = dataSources.some((ds) => ds.dataTypes?.dataSourceType === 'snowflake');

    const orgAdminItems: HierarchyData[] = [
      {
        breadcrumbLabelList: [],
        children: [],
        guid: '/admin/analytics',
        iconEl: <Icon color="currentColor" name="analytics" size="14px" />,
        name: 'Analytics',
        objectType: 'menu-item',
        url: '/admin/analytics',
      },
      {
        breadcrumbLabelList: [],
        children: [],
        guid: '/admin/users',
        iconEl: <Icon color="currentColor" name="user" size="16px" />,
        name: 'Users',
        objectType: 'menu-item',
        url: '/admin/users',
      },
      {
        breadcrumbLabelList: [],
        children: [],
        guid: '/admin/teams',
        iconEl: <Icon color="currentColor" name="team" size="18px" />,
        name: 'Teams',
        objectType: 'menu-item',
        url: '/admin/teams',
      },
      {
        breadcrumbLabelList: [],
        children: [],
        guid: '/admin/sso',
        iconEl: <Icon color="currentColor" name="key-filled" size="14px" />,
        name: 'SSO',
        objectType: 'menu-item',
        url: '/admin/sso',
      },
      {
        breadcrumbLabelList: [],
        children: appsMenuChildren,
        guid: '/admin/apps',
        name: 'Apps',
        objectType: 'apps-menu-item',
        url: '/admin/apps',
      },
      ...(flags.billing ? [billingMenuItem] : []),
      ...(settings?.enableCostAnalysis && hasSnowflake ? [constAnalysisMenuItem] : []),
      ...(settings?.automatedPii ? [automatedPiiMenuItem] : []),
      ...(settings?.useRbac ? [rbacMenuItem] : []),
    ];

    const hierarchy: HierarchyData[] = [...dataSourcesItem, ...(isOrgAdmin ? orgAdminItems : [])];

    return hierarchy;
  }, [data, organization, appsMenuChildren, isOrgAdmin, dataSourcesItem]);

  return adminHierarchy;
};

export default useAdminHierarchy;
