import { GetDataQualityUpstreamIssuesListPathParams } from '@api/openapi.generated';
import { paginatedSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import mapDataQualityTestModel, { DataQualityTestModel } from './DataQualityTestModel';

export const fetchDataQualityUpstreamIssuesSelect = paginatedSelect(mapDataQualityTestModel);
export const useFetchDataQualityUpstreamIssues = (
  guid: string,
  options?: UseFetchOptions<
    PaginatedResponse<DataQualityTestModel>,
    GetDataQualityUpstreamIssuesListPathParams
  >,
) => {
  return useFetch<PaginatedResponse<DataQualityTestModel>>({
    ...options,
    queryKey: [...cacheKeys.upstreamIssues(guid), options?.params],
    select: fetchDataQualityUpstreamIssuesSelect,
    url: `/data-quality/upstream-issues/${guid}`,
  });
};
