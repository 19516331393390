import React, { useEffect, useRef, useState } from 'react';

import { commentCacheKeys, usePatchComment } from '@api/comments';
import { CommentModel } from '@api/comments/CommentModel';
import invalidateCache from '@api/invalidateCache';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import IconButton from '@components/IconButton';
import useHighlight from '@components/Table/Table/useHighlight';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import fetchClient from '@lib/fetchClient';
import theme from '@styles/theme';

import CommentTextBox from '../CommentTextBox';
import type { CommentPayload } from '../CommentTextBox/CommentTextBox.types';
import Recipients from '../Recipients';

import {
  MessageBody,
  MessageContent,
  MessageControls,
  ReplyList,
  StyledMessage,
} from './Message.styles';
import MessageInfo from './MessageInfo';

interface MessageProps {
  businessOwner?: UserModel | TeamModel;
  comment: CommentModel;
  editingNotificationGuid?: string;
  hasEditPermissions?: boolean;
  isReply?: boolean;
  onNotificationUpdate?: (payload: CommentModel) => void;
  onSave: (payload: CommentPayload) => void;
  parentGuid?: string;
  reloadComments: () => void;
  technicalOwner?: UserModel | TeamModel;
}

const Message: React.FC<MessageProps> = ({
  businessOwner,
  comment,
  editingNotificationGuid,
  hasEditPermissions,
  isReply,
  onNotificationUpdate,
  onSave,
  parentGuid,
  reloadComments,
  technicalOwner,
}) => {
  const { isOrgAdmin, organization, user } = useUserContext();
  const useDownstreamNotifications = organization?.settings?.useDownstreamNotifications;
  const { MODAL_IDS, openModal } = useModal();
  const [isEditing, setIsEditing] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [isPinning, setIsPinning] = useState(false);
  const [isPinned, setIsPinned] = useState(comment.pinnedAt?.isValid());
  const [message, setMessage] = useState<string | undefined>(comment.richtextMessage);
  const replyListRef = useRef<HTMLDivElement>(null);
  const { highlightedRowId, isHighlightedRowActive, setIsHighlightedRowActive } = useHighlight();
  const isHighlighted = isHighlightedRowActive && highlightedRowId === comment?.guid;
  const authedGuid = user?.guid;
  const commentAuthorGuid = comment?.commentedBy?.guid;

  const isCommentOwner = authedGuid === commentAuthorGuid;
  const isObjectOwner = authedGuid === businessOwner?.guid || authedGuid === technicalOwner?.guid;
  const isEditable = isCommentOwner || isOrgAdmin || isObjectOwner || hasEditPermissions;
  const canPin = isObjectOwner || hasEditPermissions;
  const isNotification = (comment.commentRecipients ?? []).length > 0 && useDownstreamNotifications;

  const isEditingNotification = editingNotificationGuid === comment.guid;

  const {
    data,
    isError,
    isLoading,
    mutate: updateComment,
  } = usePatchComment(comment.guid, {
    onSuccess: () => {
      fetchClient.invalidateQueries(commentCacheKeys.comments);
      invalidateCache((keys) => [keys.metadata.metadataComments]);
      renderInfoToast('Comment updated');

      if (isPinning) {
        setIsPinning(false);
      }
    },
  });

  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isError) {
      renderErrorToast('There was an error saving your comment.');
    }
    if (data) {
      reloadComments();
    }
  }, [data, isError, reloadComments]);

  // If message is highlighted, scroll to it.
  useEffect(() => {
    if (isHighlighted && messageRef.current) {
      /*
       * We need to offset where we scroll to from the top of the page because tabs/header area will be blocking the comment
       * if scrolled to the very top.
       */
      const topOffset = 280;
      const top = messageRef.current.getBoundingClientRect().top - topOffset;
      window?.scrollTo({ behavior: 'smooth', top });
    }
  }, [isHighlighted, messageRef]);

  const onUpdate = (payload: CommentPayload) => {
    setIsEditing(false);
    updateComment({
      ...payload,
      comment_recipients: (comment.commentRecipients ?? []).map((el) => el.obj?.guid),
    });
    // Save message in state so we don't get a flash on screen of the old message.
    setMessage(payload?.richtext_message || payload?.message || '');
  };

  const handlePin = () => {
    if (!canPin) return;

    setIsPinning(true);
    const payload: CommentPayload = {
      pinned: !isPinned,
    };
    setIsEditing(false);
    updateComment(payload);
    setIsPinned((current) => !current);
  };

  const handleOnSave = (payload: CommentPayload) => {
    setIsReplying(false);
    onSave(payload);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleCommentEdit = () => {
    if (isNotification && onNotificationUpdate) {
      onNotificationUpdate(comment);
      setMessage(undefined);
    } else {
      setIsEditing(!isEditing);
    }
  };

  const handleDoubleClick = (e: React.MouseEvent) => {
    const isInsideReply =
      e.target === replyListRef.current || replyListRef.current?.contains(e.target as Node);
    if (isCommentOwner && !isInsideReply) {
      handleCommentEdit();
    }
  };

  const hasReplies = comment.replies && comment.replies?.length > 0;

  return (
    <StyledMessage
      ref={messageRef}
      isHighlighted={isHighlighted}
      isReplyMessage={isReply}
      onMouseEnter={() => setIsHighlightedRowActive(false)}
    >
      <MessageInfo comment={comment} />
      <MessageContent>
        <MessageBody isReplyMessage={isReply} onDoubleClick={handleDoubleClick}>
          {isEditing && comment.guid && parentGuid ? (
            <>
              <CommentTextBox
                editCommentGuid={comment.guid}
                editing
                initialValue={comment.message}
                onCancel={handleCancel}
                onSave={handleOnSave}
                onUpdate={onUpdate}
                parentGuid={parentGuid}
              />
              <MessageControls />
            </>
          ) : (
            <>
              {isLoading && !isPinning ? (
                <CircularLoader centered compSize={4} />
              ) : (
                <Box mb={1.5}>
                  {isEditingNotification ? (
                    <CircularLoader centered compSize={4} />
                  ) : (
                    <CommentTextBox
                      editCommentGuid={comment.guid}
                      initialValue={message ?? comment.richtextMessage}
                      onCancel={handleCancel}
                      onSave={handleOnSave}
                      onUpdate={onUpdate}
                      parentGuid={parentGuid || ''}
                    />
                  )}
                </Box>
              )}
              {isCommentOwner && isNotification && !isEditingNotification && (
                <Recipients recipients={comment.commentRecipients!} />
              )}
              {(isEditable || !isReply) && (
                <MessageControls hasReplies={hasReplies || isReplying}>
                  {isCommentOwner && (
                    <IconButton
                      color={theme.colors.v1.gray[400]}
                      label="Edit"
                      onClick={handleCommentEdit}
                    >
                      <Icon color={theme.colors.v1.gray[400]} name="edit-outline" size="1rem" />
                    </IconButton>
                  )}
                  {!isReply && (
                    <IconButton
                      color={theme.colors.v1.gray[400]}
                      label="Reply"
                      onClick={() => setIsReplying(!isReplying)}
                    >
                      <Icon color={theme.colors.v1.gray[400]} name="message" size="1rem" />
                    </IconButton>
                  )}
                  {isEditable && (
                    <IconButton
                      color={theme.colors.v1.gray[400]}
                      label="Delete"
                      onClick={() => openModal(MODAL_IDS.deleteComment, { item: comment })}
                    >
                      <Icon color={theme.colors.v1.gray[400]} name="trash" size="1rem" />
                    </IconButton>
                  )}
                  {!isReply && (canPin || isPinned) && (
                    <IconButton
                      color={theme.colors.v1.gray[400]}
                      label={isPinned ? 'Pinned' : 'Pin'}
                      onClick={handlePin}
                    >
                      <Icon
                        color={theme.colors.v1.gray[400]}
                        name={isPinned ? 'pin-selected' : 'pin-unselected'}
                        size="1rem"
                      />
                    </IconButton>
                  )}
                </MessageControls>
              )}
            </>
          )}
          <ReplyList ref={replyListRef}>
            {comment?.replies?.map((reply) => (
              <Message
                key={reply.guid}
                businessOwner={businessOwner}
                comment={reply}
                hasEditPermissions={hasEditPermissions}
                isReply
                onSave={handleOnSave}
                parentGuid={parentGuid}
                reloadComments={reloadComments}
                technicalOwner={technicalOwner}
              />
            ))}
            {isReplying && comment.guid && parentGuid && (
              <StyledMessage isReplyMessage>
                <CommentTextBox
                  inReplyTo={comment.guid}
                  isNewMessage
                  onCancel={() => {
                    setIsReplying(false);
                  }}
                  onSave={handleOnSave}
                  onUpdate={onUpdate}
                  parentGuid={parentGuid}
                />
              </StyledMessage>
            )}
          </ReplyList>
        </MessageBody>
      </MessageContent>
    </StyledMessage>
  );
};

export default React.memo(Message);
