import React, { forwardRef } from 'react';

import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';

import type { StyledIconButtonProps, StyledIconButtonWrapperProps } from './IconButton.styles';
import {
  StyledIconButton,
  StyledIconButtonLabel,
  StyledIconButtonWrapper,
} from './IconButton.styles';

export interface IconButtonProps
  extends StyledIconButtonProps,
    StyledIconButtonWrapperProps,
    Pick<IconProps, 'spinLeft'> {
  ariaLabel?: string;
  className?: string;
  iconName?: IconProps['name'];
  iconSize?: IconProps['size'];
  label?: React.ReactNode;
  type?: 'submit' | 'button';
}

const IconButton = forwardRef<HTMLDivElement, IconButtonProps>(
  (
    {
      ariaLabel,
      backgroundColor,
      children,
      color,
      fontSize,
      hoverBackgroundColor,
      hoverColor,
      href,
      iconColor = 'currentColor',
      iconName,
      iconSize,
      label = null,
      size = 'default',
      spinLeft,
      variant,
      ...other
    },
    ref,
  ) => {
    return (
      <StyledIconButton
        aria-label={ariaLabel ?? iconName}
        as={href ? 'a' : 'button'}
        hoverBackgroundColor={hoverBackgroundColor}
        hoverColor={hoverColor}
        href={href}
        size={size}
        variant={variant}
        {...other}
        ref={ref}
      >
        <StyledIconButtonWrapper backgroundColor={backgroundColor} size={size} variant={variant}>
          {iconName && (
            <Icon color={iconColor} name={iconName} size={iconSize} spinLeft={spinLeft} />
          )}
          {children}
        </StyledIconButtonWrapper>
        {label && (
          <StyledIconButtonLabel color={color} fontSize={fontSize}>
            {label}
          </StyledIconButtonLabel>
        )}
      </StyledIconButton>
    );
  },
);

export default IconButton;
