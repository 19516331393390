import React from 'react';

import Button from '@components/Button/Button';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

export interface MentionedByBadgeProps {
  iconName?: IconProps['name'];
  label?: string;
  onClick?: () => void;
  textColor?: string;
}

const MentionedByBadge: React.FC<MentionedByBadgeProps> = ({
  iconName = 'comment-filled',
  label = 'Mentioned',
  onClick,
  textColor = theme.colors.lightblue,
}) => {
  return (
    <Button
      backgroundColor="#f2f5f9"
      borderRadius={theme.radius.default}
      color={textColor}
      compSize="xxs"
      fontSize="12px"
      fontWeight="semibold"
      iconCurrentColor
      onClick={onClick}
      spacing={0.5}
      startIcon={<Icon name={iconName} size="12px" />}
      variant="outlined"
    >
      {label}
    </Button>
  );
};

export default MentionedByBadge;
