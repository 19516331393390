import React from 'react';

import Button from '@components/Button';
import { ButtonProps } from '@components/Button/Button';
import Icon, { IconProps } from '@components/UI/Icon';

interface ColumnConstaintBadgeProps extends ButtonProps {
  icon?: IconProps['name'];
  name: string;
}

const ColumnConstaintBadge = ({ as = 'span', icon, name, ...rest }: ColumnConstaintBadgeProps) => {
  return (
    <Button
      as={as}
      borderRadius="default"
      color="primary.1000"
      compHeight="20px"
      fontSize="12px"
      fontWeight="semibold"
      variant="constraint"
      {...rest}
    >
      {icon && <Icon name={icon} size="12px" />}
      {name}
    </Button>
  );
};

export default ColumnConstaintBadge;
