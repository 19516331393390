import React from 'react';

import { useModal } from '@context/Modal';
import theme from '@styles/theme';

import MentionedByBadge, { MentionedByBadgeProps } from './MentionedByBadge';

interface MentionedByModalWithAnchorProps extends Omit<MentionedByBadgeProps, 'onClick'> {
  filterByMetrics?: boolean;
  guid: string;
}

const MentionedByModalWithAnchor: React.FC<MentionedByModalWithAnchorProps> = ({
  filterByMetrics = false,
  guid,
  iconName = 'message',
  label = 'Mentioned',
  textColor = theme.colors.lightblue,
}) => {
  const { MODAL_IDS, openModal } = useModal();

  return (
    <MentionedByBadge
      iconName={iconName}
      label={label}
      onClick={() => {
        openModal(MODAL_IDS.mentionedBy, { filterByMetrics, targetId: guid });
      }}
      textColor={textColor}
    />
  );
};

export default MentionedByModalWithAnchor;
