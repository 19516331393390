import React from 'react';

import { ColumnModel } from '@api/columns/ColumnModel';
import MentionedByModalWithAnchor from '@components/MentionedBy/MentionedByModalWithAnchor';
import DeletedLabel from '@components/Table/DeletedLabel';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import type { StyledColumnContraintProps } from './ColumnConstraint.styles';
import {
  StyledColumnConstraint,
  StyledColumnConstraintIconButton,
} from './ColumnConstraint.styles';
import ColumnConstaintBadge from './ColumnConstraintBadge';

interface ColumnConstraintProps extends StyledColumnContraintProps {
  columnItem?: ColumnModel;
  editPk?: (value: ColumnModel) => void;
  hideConstraints?: boolean;
  isEditable?: boolean;
  showUsageBadges?: boolean;
}

const ColumnConstraint: React.FC<ColumnConstraintProps> = ({
  columnItem,
  editPk,
  gap = 0.5,
  hideConstraints,
  isEditable,
  showUsageBadges,
  ...other
}) => {
  const isClickable = isEditable && !!editPk;
  return (
    <StyledColumnConstraint gap={gap} {...other}>
      {!hideConstraints && (
        <>
          {columnItem && isClickable && (
            <StyledColumnConstraintIconButton
              onClick={() => {
                editPk?.(columnItem);
              }}
            >
              <Icon color="gray.400" name="plus" size="16px" />
            </StyledColumnConstraintIconButton>
          )}
          {columnItem?.isPrimaryKey && <ColumnConstaintBadge icon="key" name="PK" noHover />}
          {columnItem?.isForeignKey && (
            <ColumnConstaintBadge
              as={isClickable ? 'button' : 'span'}
              icon="key-gray"
              name="FK"
              noHover={!isClickable}
              onClick={() => isClickable && editPk(columnItem)}
            />
          )}
          {columnItem?.isNullable === false && <ColumnConstaintBadge name="NOT NULL" noHover />}
          {columnItem?.isUnique && <ColumnConstaintBadge name="UNIQUE" noHover />}
        </>
      )}
      {showUsageBadges && (
        <>
          {columnItem?.isMention && <MentionedByModalWithAnchor guid={columnItem?.guid} />}
          {columnItem?.isMetric && (
            <MentionedByModalWithAnchor
              filterByMetrics
              guid={columnItem?.guid}
              iconName="metric"
              label="Metric"
              textColor={theme.colors.hyperlink}
            />
          )}
          {columnItem?.deactivationScheduledOn && <DeletedLabel />}
        </>
      )}
    </StyledColumnConstraint>
  );
};

export default ColumnConstraint;
